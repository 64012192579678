import React, { ComponentProps, useEffect, useState } from 'react'
import { AntdLayout, Menu, Grid, Icons } from '@pankod/refine-antd'
import { SiderProps } from 'antd'
import {
  useLogout,
  useTitle,
  ITreeMenu,
  useIsExistAuthentication,
  useCanWithoutCache,
  useRouterContext,
  useMenu,
  useRefineContext,
} from '@pankod/refine-core'
import clsx from 'clsx'

import { Title as DefaultTitle } from '../title'
import { antLayoutSider, antLayoutSiderMobile } from './styles'
import { getTheme } from 'src/helpers/theme'
import { useGetSelectedKey } from './utils'

const { LogoutOutlined, UnorderedListOutlined } = Icons

type ItemType = NonNullable<ComponentProps<typeof Menu>['items']>[number]

type Sider = {
  selectedKeys?: string[]
  defaultOpenKeys?: string[]
}

export const Sider: React.FC<Sider> = (props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const isExistAuthentication = useIsExistAuthentication()
  const { Link } = useRouterContext()
  const { hasDashboard } = useRefineContext()
  const { mutate: logout } = useLogout()
  const Title = useTitle()
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu()
  const breakpoint = Grid.useBreakpoint()
  const { can: checkCanAccess } = useCanWithoutCache()
  const [renderedMenuItems, setRenderedMenuItems] = React.useState<
    Array<ItemType>
  >([])
  const getSelectedKey = useGetSelectedKey(menuItems)

  const isMobile = !breakpoint.lg

  const RenderToTitle = Title ?? DefaultTitle

  const handleTreeMenuItem = React.useCallback(
    async (
      item: ITreeMenu & { type?: 'divider' | 'group' },
      selectedKey?: string,
    ): Promise<ItemType | undefined> => {
      if (item.children?.length > 0) {
        const handledChildren = (
          await Promise.all(
            item.children.map((child) =>
              handleTreeMenuItem(child, selectedKey),
            ),
          )
        ).filter(Boolean)
        // is sub
        if (handledChildren.length > 0) {
          return {
            key: item.route,
            label: item.label,
            icon: item.icon ?? <UnorderedListOutlined />,
            title: item.label,
            children: handledChildren,
            type: item.type,
          } as ItemType
        }

        return undefined
      } else {
        let isSelected = item.route === selectedKey
        if (item.options?.include) {
          isSelected = isSelected || item.options.include.includes(selectedKey)
        }
        const isRoute = !(
          item.parentName !== undefined && item.children.length === 0
        )

        const { can: hasAccess } = checkCanAccess
          ? await checkCanAccess({
              resource: item.name,
              action: 'list',
            })
          : { can: true }
        // is leaf
        if (hasAccess) {
          const LabelWrapper = item.route
            ? Link
            : (React.Fragment as typeof Link)
          return {
            key: item.route,
            label: (
              <LabelWrapper href={item.route} to={item.route}>
                {item.label}
                {!collapsed && isSelected && (
                  <div className="ant-menu-tree-arrow" />
                )}
              </LabelWrapper>
            ),
            icon: item.icon ?? (isRoute && <UnorderedListOutlined />),
            className: clsx(
              isSelected && 'ant-menu-item-selected font-bold',
              'first:!mt-0',
            ),
          } as ItemType
        }
        return undefined
      }
    },
    [checkCanAccess, Link, collapsed],
  )

  const renderTree = React.useCallback(
    async (items: ITreeMenu[], selected: string) => {
      const promises = items.map((item) => handleTreeMenuItem(item, selected))
      setRenderedMenuItems(
        (await Promise.all(promises)).filter(Boolean) as ItemType[],
      )
    },
    [handleTreeMenuItem],
  )

  useEffect(() => {
    renderTree(menuItems, selectedKey)
  }, [menuItems, renderTree, selectedKey])
  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={isMobile ? 0 : 80}
      breakpoint="lg"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
      width={250}
      theme={getTheme() as SiderProps['theme']}
    >
      <RenderToTitle collapsed={collapsed} />
      <Menu
        selectedKeys={props.selectedKeys || [getSelectedKey(selectedKey)]}
        defaultOpenKeys={props.defaultOpenKeys || defaultOpenKeys}
        mode="inline"
        onClick={() => {
          if (!breakpoint.lg) {
            setCollapsed(true)
          }
        }}
        items={[
          ...(hasDashboard
            ? [
                {
                  key: 'Homepage',
                  className: clsx(
                    selectedKey === '/' && 'ant-menu-item-selected font-bold',
                    'first:!mt-0',
                  ),
                  icon:
                    selectedKey === '/' ? (
                      <Icons.HomeTwoTone />
                    ) : (
                      <Icons.HomeFilled />
                    ),
                  label: (
                    <Link href="/" to="/">
                      Homepage
                      {!collapsed && selectedKey === '/' && (
                        <div className="ant-menu-tree-arrow" />
                      )}
                    </Link>
                  ),
                } as ItemType,
              ]
            : []),
          ...renderedMenuItems,

          {
            key: 'paduan-lms',
            icon: <Icons.CustomerServiceOutlined />,
            className: 'text-blue-600 ',
            style: { marginTop: '30px' },
            label: (
              <Link
                href={process.env.NEXT_PUBLIC_PUSAT_INFORMASI_LMS_BASE_URL}
                target="_blank"
              >
                <span className="text-blue-600 ">Panduan Seputar LMS</span>
              </Link>
            ),
          },

          {
            key: 'paduan-belajar-id',
            className: 'text-blue-600',
            icon: <Icons.LinkOutlined />,
            label: (
              <Link
                href={process.env.NEXT_PUBLIC_PUSAT_INFORMASI_LMS_BASE_URL}
                target="_blank"
              >
                <span className="text-blue-600">
                  Panduan Seputar belajar.id
                </span>
              </Link>
            ),
          },

          ...(isExistAuthentication
            ? [
                {
                  key: 'logout',
                  label: 'Logout',
                  onClick: () => logout(),
                  danger: true,
                  icon: <LogoutOutlined />,
                } as ItemType,
              ]
            : []),
        ]}
      />
    </AntdLayout.Sider>
  )
}
